import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'
import pathToRegexp from 'path-to-regexp'

import { baseUrl } from './config'

axios.defaults.baseURL = baseUrl

function fetch(options) {
    let {
        method = 'get',
        headers = {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        },
        data,
        url,
    } = options

    const cloneData = _.cloneDeep(data)

    try {
        let domin = ''
        if (url.match(/[a-zA-z]+:\/\/[^/]*/)) {
            domin = url.match(/[a-zA-z]+:\/\/[^/]*/)[0]
            url = url.slice(domin.length)
        }
        const match = pathToRegexp.parse(url)
        url = pathToRegexp.compile(url)(data)
        for (let item of match) {
            if (item instanceof Object && item.name in cloneData) {
                delete cloneData[item.name]
            }
        }
        url = domin + url
    } catch (e) {
        console.log(e.message)
    }

    switch (method.toLowerCase()) {
        case 'get':
            return axios.get(url, {
                params: cloneData
            })
        case 'post':
            return axios.post(url, qs.stringify(cloneData, { encode: false }), {
                headers
            })
        case 'put':
            return axios.put(url, qs.stringify(cloneData, { encode: false }), {
                headers
            })
        case 'delete':
            return axios.delete(url, {
                data: qs.stringify(cloneData, { encode: false }),
                headers
            })
        default:
            return axios.request({
                url,
                method,
                headers,
                data: qs.stringify(cloneData, { encode: false }),
            })
    }
}

export default function request(options) {
    return new Promise((resolve, reject) => {
        fetch(options).then((response) => {
            const { statusText, status } = response
            let data = response.data
            resolve({
                success: true,
                message: statusText,
                status,
                data,
            })
        }).catch((error) => {
            const { response } = error
            let msg
            let status
            let otherData = {}
            if (response) {
                const { data, statusText } = response
                otherData = data
                status = response.status
                msg = data.message || statusText
            } else {
                status = 600
                msg = 'Network Error'
            }
            // layer.msg(msg)
            reject({
                success: false,
                status,
                message: msg,
                data: otherData
            })
        })

    })
}