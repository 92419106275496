import _ from 'lodash'
import editorTools from '../../utils/editorTools'

export default (id, toolName, opt) => {
    var toolbars = editorTools['all'];
    if (editorTools[toolName]) {
        toolbars = editorTools[toolName]
    }
    var option = _.assign({
        autoHeightEnabled: false,
        allowDivTransToP: false,
        autoFloatEnabled: true,
        enableAutoSave: false,
        serverUrl: '/Public/js/plugins/ueditor/1.4.3/php/controller.php?imagePathFormat=/upload/twstyle/{time}{rand:6}'
    }, { toolbars }, opt)
    var ue = UE.getEditor(id, option);
    return ue;
}