import Vue from 'vue'
import ElementUI from 'element-ui'
import _ from 'lodash'
import qs from 'qs'

import * as utils from './utils'
import * as components from './components'
import css from './index.css'

!(function () {
    Vue.use(ElementUI)

    //基础工具
    window.ZDBaseUtils = utils

    //组件
    window.ZDBTCURD = components.Curd.BtCurd
    window.ZDEditor = components.Editor
    window.ZDUploader = components.Uploader
    window.ZDInput = components.Input
    window.ZDDataList = components.DataList
    window.ZDMedia = components.Media

    //其他需要暴露到全局的第三方工具
    window.Vue = Vue
    window._ = _
    window.qs = qs
    window.pdf = pdfjsLib
})()