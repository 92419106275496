import _ from 'lodash'

import request from './request'
import config from './config'
import Enum from './enum'
import frontWrap from './fontWrap'
import mp4Parser from './mp4parser'
import * as Brower from './brower'

// 连字符转驼峰
String.prototype.hyphenToHump = function () {
    return this.replace(/-(\w)/g, (...args) => {
        return args[1].toUpperCase()
    })
}

// 驼峰转连字符
String.prototype.humpToHyphen = function () {
    return this.replace(/([A-Z])/g, '-$1').toLowerCase()
}

// 日期格式化
Date.prototype.format = function (format) {
    const o = {
        'M+': this.getMonth() + 1,
        'd+': this.getDate(),
        'h+': this.getHours(),
        'H+': this.getHours(),
        'm+': this.getMinutes(),
        's+': this.getSeconds(),
        'q+': Math.floor((this.getMonth() + 3) / 3),
        S: this.getMilliseconds(),
    }
    if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : (`00${o[k]}`).substr(`${o[k]}`.length))
        }
    }
    return format
}


/**
 * @param   {String}
 * @return  {String}
 */

const queryURL = (name) => {
    let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
    let r = window.location.search.substr(1).match(reg)
    if (r != null) return decodeURI(r[2])
    return null
}

/**
 * 数组内查询
 * @param   {array}      array
 * @param   {String}    id
 * @param   {String}    keyAlias
 * @return  {Array}
 */
const queryArray = (array, key, keyAlias = 'key') => {
    if (!(array instanceof Array)) {
        return null
    }
    const item = array.filter(_ => _[keyAlias] === key)
    if (item.length) {
        return item[0]
    }
    return null
}

/**
 * 数组格式转树状结构
 * @param   {array}     array
 * @param   {String}    id
 * @param   {String}    pid
 * @param   {String}    children
 * @return  {Array}
 */
const arrayToTree = (array, id = 'id', pid = 'pid', children = 'children') => {
    let data = _.cloneDeep(array)
    let result = []
    let hash = {}
    data.forEach((item, index) => {
        hash[data[index][id]] = data[index]
    })

    data.forEach((item) => {
        let hashVP = hash[item[pid]]
        if (hashVP) {
            !hashVP[children] && (hashVP[children] = [])
            hashVP[children].push(item)
        } else {
            result.push(item)
        }
    })
    return result
}

/**
 * Javascript base64encode() base64加密函数
 * 用于生成字符串对应的base64加密字符串
 * @param   {string}    input
 * @return  {string}
 */
const base64Encode = (input) => {

    var rv;
    rv = encodeURIComponent(input);
    rv = unescape(rv);
    rv = window.btoa(rv);
    return rv;
}

/**
 * Javascript base64Decode() base64解密函数
 * 用于解密base64加密的字符串
 * @param   {string}    input
 * @return  {string}
 */
const base64Decode = (input) => {
    var rv;
    rv = window.atob(input);
    rv = escape(rv);
    rv = decodeURIComponent(rv);
    return rv;
}

/**
 * 转换枚举值，读取上面Enum设置的参数
 * @param   {string}    enumKey
 * @param   {string}    wrapKey
 * @return  {string}
 */
const enumToValue = (enumKey, wrapKey) => {
    if (!Enum[enumKey]) {
        console.warn('枚举值不存在！');
    }
    return function (val) {
        if (!Enum[enumKey]) {
            return '';
        }

        var str = Enum[enumKey][val];

        if (frontWrap[wrapKey]) {
            var str = '<' + frontWrap[wrapKey].tag + ' class="' + frontWrap[wrapKey].className[val] + '" >' + str + '</' + frontWrap[wrapKey].tag + '>'
        }

        return str;
    }
}

/**
 * 接口返回datetime格式日期，将其格式化为日期字符串
 * @param   {string}    val
 * @return  {string}
 */
const toDate = (val) => {
    var date = new Date(val);
    if (val == null || val == 0 || date == 'Invalid Date') {
        return null;
    }

    return date.format('yyyy-MM-dd');
}

export {
    queryURL,
    queryArray,
    arrayToTree,
    base64Encode,
    base64Decode,
    enumToValue,
    toDate,
    request,
    config,
    Enum,
    mp4Parser,
    Brower,
}