import Vue from 'vue'
import _ from 'lodash'

import BaseVue from '../BaseVue'
import { Brower } from '../../utils'

/**
 * 用法
 * 
 * 
 *			new ZDInput.CameraSnapshot({
 *				el: '#catest',
 *			})
 */

export default class CameraSnapshot extends BaseVue {
    constructor(props) {
        super(props)

        this.shot = this.shot.bind(this)
    }

    shot() {
        const {
            onCapture
        } = this.props

        if (Brower.isIE()) {
            webcam.capture()
        } else {
            onCapture(this.vm.handleShot())
        }
    }

    render() {
        const {
            el,
            width = 320,
            height = 240,
            onCapture = function () { }
        } = this.props

        if (Brower.isIE()) {
            let pos = 0
            let canvasSnapshot = document.createElement('canvas')
            canvasSnapshot.setAttribute('width', width)
            canvasSnapshot.setAttribute('height', height)
            let ctx = canvasSnapshot.getContext('2d')
            let image = ctx.getImageData(0, 0, width, height)
            return $(el).webcam({
                width: width,
                height: height,
                mode: "callback",
                swffile: "https://cdn.bootcss.com/jQuery-webcam/1.0.0/jscam_canvas_only.swf",
                onTick: function () { },
                onSave: function (data) {
                    // console.log(data)
                    var col = data.split(";");
                    var img = image

                    for (var i = 0; i < width; i++) {
                        var tmp = parseInt(col[i]);
                        img.data[pos + 0] = (tmp >> 16) & 0xff;
                        img.data[pos + 1] = (tmp >> 8) & 0xff;
                        img.data[pos + 2] = tmp & 0xff;
                        img.data[pos + 3] = 0xff;
                        pos = pos + 4;
                    }

                    if (pos >= 4 * width * height) {
                        ctx.putImageData(img, 0, 0);
                        pos = 0;
                        onCapture((canvasSnapshot.toDataURL('image/jpg')))
                    }
                },
                onCapture: function () {
                    webcam.save();
                },
                debug: function (type, string) {
                    console.log(type + ':' + string)
                },
                onLoad: function () { }
            });
        } else {
            return new Vue({
                template: `
                <div id="camera">
                    <video id="camera_snapshot" autoplay width="${width}" height="${height}" ref="cameraSnapshot" style="" ></video>
                    <canvas id="canvas" style="display:none;" width="${width}" height="${height}" ref="canvasSnapshot"></canvas>
                </div>
                `,
                methods: {
                    handleShot() {
                        const context = this.$refs.canvasSnapshot.getContext('2d')
                        context.drawImage(this.$refs.cameraSnapshot, 0, 0, width, height)
                        var dataUrl = this.$refs.canvasSnapshot.toDataURL('image/jpg')
                        return dataUrl;
                    },
                },
                mounted: function () {
                    navigator.mediaDevices.getUserMedia({ video: { width: width, height: height, facingMode: 'user' } })
                        .then((stream) => {
                            this.$refs.cameraSnapshot.srcObject = stream
                        }).catch((err) => {
                            console.error(err)
                            layer.msg('访问摄像头被拒绝，请检查环境是否支持或联系管理员！')
                        })
                }
            }).$mount(el)
        }
    }
}