import qs from 'qs'
import Vue from 'vue'

import BaseVue from '../BaseVue'

/**
 * 用法
 * 
 * 
 *			new ZDDataList.Pagination({
 *				el: '#recommend_list',
 *				pageSize: 10,
 *              total: 100
 *			})
 */

export default class Pagination extends BaseVue {
    render() {
        const { el, pageSize = 10, total } = this.props
        return new Vue({
            template: `
            <ul v-if="total > pageSize">
                <li>
                    <a @click.prevent="handleFirst">首页</a>
                </li>
                <li v-if="currentPage > 1">
                    <a @click.prevent="handlePrev">上一页</a>
                </li>
                <li v-for="page in pageColumn" key="page"  :class="page == currentPage ? 'on' : ''">
                    <a :data-page="page" @click.prevent="handleLocation" v-text="page"></a>
                </li>
                <li v-if="currentPage < Math.ceil(total / pageSize)">
                    <a @click.prevent="handleNext">下一页</a>
                </li>
                <li>
                    <a @click.prevent="handleLast">尾页</a>
                </li>
            </ul>
            `,
            data: {
                queryObject: {},
                pageSize: pageSize,
                total: total,
                currentPage: 1,
                pageColumn: [],
            },
            methods: {
                init() {
                    const queryString = window.location.search.replace('?', '');
                    const queryObject = qs.parse(queryString)
                    const totalPage = Math.ceil(this.total / this.pageSize);
                    const pageAll = (new Array(totalPage)).fill(1).map((item, idx) => (idx + 1));
                    let pageColumn = pageAll

                    if (queryObject.page) {
                        this.currentPage = queryObject.page
                    }

                    if (pageAll.length > 5) {
                        const currentPageIndex = pageAll.indexOf(this.currentPage)

                        if (currentPageIndex >= 2 && currentPageIndex <= totalPage - 3) {
                            pageColumn = pageAll.splice(currentPageIndex - 2, currentPageIndex + 3);
                        } else if (currentPageIndex < 2) {
                            pageColumn = pageAll.splice(0, 5);
                        } else if (currentPageIndex <= totalPage - 3) {
                            pageColumn = pageAll.splice(totalPage - 6, totalPage - 1);
                        }
                    }

                    this.queryObject = queryObject
                    this.pageColumn = pageColumn
                },
                locationTo(queryObject) {
                    window.location.href = '?' + qs.stringify(queryObject);
                },
                handleFirst() {
                    const queryObject = this.queryObject
                    queryObject.page = 1

                    this.locationTo(queryObject)
                },
                handleLast() {
                    const queryObject = this.queryObject
                    queryObject.page = Math.ceil(this.total / this.pageSize)

                    this.locationTo(queryObject)
                },
                handlePrev() {
                    const queryObject = this.queryObject
                    queryObject.page = parseInt(queryObject.page) - 1

                    this.locationTo(queryObject)
                },
                handleNext() {
                    const queryObject = this.queryObject
                    queryObject.page = parseInt(queryObject.page) + 1

                    this.locationTo(queryObject)
                },
                handleLocation(e) {
                    const page = e.target.dataset.page
                    const queryObject = this.queryObject
                    queryObject.page = page

                    this.locationTo(queryObject)
                }
            },
            mounted() {
                this.init()
            }
        }).$mount(el)
    }
}