export default {
    //加盟机构用户枚举字段
    leagueState: {
        '0':"待审核", 
        '1': "已审核", 
        '2': "不通过", 
        '3': "停用"
    },
    //发票枚举字段
    invoiceType: {
        '0': '个人',
        '1': '企业'
    },
    invoiceKind: {
        '0': '普通发票',
        '1': '专用发票'
    },
    invoiceState: {
        '0': '已申请',
        '1': '已开票',
        '2': '已邮寄'
    },
    paymentInvoiceState: {
        '0': '未开票',
        '1': '已开票'
    },
    //订单枚举字段
    orderPayMethods: {
        '0': '线上',
        '1': '线下',
    },
    orderState: {
        '5': '欠费',
        '0': '待支付',
        '1': '支付异常',
        '2': '已支付',
        '3': '申请退款中',
        '4': '已退款',
    },
    //班级枚举字段
    teachMethod: {
        '0': '面授',
        '1': '网络'
    },
    classesType: {
        '0': '定制',
        '1': '自选',
    },
    classesCheck: {
        '0': '未审核',
        '1': '已审核',
        '2': '退回修改'
    },
    materialState: {
        '0': '未交齐',
        '1': '已交齐',
    },
    //课件枚举字段
    coursewareFileType: {
        '0': '视频',
        '1': 'PDF',
    },
    coursewareUrlType: {
        '0': '本地',
        '1': '外链',
    },
    coursewareState: {
        '0': '全部可用',
        '1': '主站可用',
        '2': '主站可用，加盟选用',
        '3': '停用',
    },
    coursewareVerification: {
        '0': '不检测',
        '1': '人脸检测',
        '2': '答题',
    },
    //证书枚举字段
    certificateType: {
        '0': '学校颁发',
        '1': '个人证书'
    },
    certificateState: {
        '0': '待交资料',
        '1': '申请中',
        '2': '发证中',
        '3': '已领证',
    },
    //通用枚举字段
    commonOpen: {
        '0': '不公开',
        '1': '公开'
    },
    commonState: {
        '0': '启用',
        '1': '禁用'
    },
    sex: {
        '0': '男',
        '1': '女'
    },
    bindingState: {
        '0': '未绑定',
        '1': '已绑定'
    },
    show: {
        '0': '上架',
        '1': '下架'
    }
}