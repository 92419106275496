import Vue from 'vue'
import _ from 'lodash'

import BaseVue from '../BaseVue'

/**
 * 用法
 * 
 * 
 *			new ZDInput.SearchSelector({
 *				el: '#catest',
 *			})
 */

export default class SearchSelector extends BaseVue {
    constructor(props) {
        super(props)
        this.research = this.research.bind(this)
    }

    research() {
        this.vm.onSearch()
    }

    render() {
        const {
            el,
            beforeSearch = function () {},
            afterSearch = function (res) {},
            onChange = function () {},
            otherQuery = {},
            optionFormat = {
                key: 'item.id',
                label: 'item.name',
                value: 'item.id'
            },
            remoteUrl = '',
            defaultValue = '',
            placeholder = '请输入关键字'
        } = this.props

        return new Vue({
            template: `
            <el-select
                v-model="selected"
                filterable
                clearable
                remote
                reserve-keyword
                placeholder="${placeholder}"
                size="small"
                style="width: 100%;"
                :remote-method="onSearch"
                :loading="loading"
                :disabled="disabled"
                @change="handleChange">
                    <el-option
                        v-for="item in options"
                        :key="${optionFormat.key}"
                        :label="${optionFormat.label}"
                        :value="${optionFormat.value}">
                    </el-option>
            </el-select>
            `,
            data: {
                loading: false,
                disabled: false,
                options: [],
                selected: defaultValue,
                otherQuery,
            },
            methods: {
                onSearch(query) {
                    beforeSearch.call(this, query)
                    this.loading = true;

                    ZDBaseUtils.request({
                        url: remoteUrl,
                        data: {
                            key: query,
                            ...otherQuery
                        }
                    }).then((res) => {
                        if (res.success) {
                            this.options = res.data.value
                            afterSearch.call(this, res)
                        }
                    }).catch((err) => {
                        console.error(err)
                    }).finally(() => {
                        this.loading = false
                    })
                },
                handleChange(value) {
                    const options = _.cloneDeep(this.options)

                    const selected = options.filter((option) => option.id === value)

                    onChange(value, ...selected)
                },
            },
            mounted() {
                this.onSearch()
            },
        }).$mount(el)
    }
}