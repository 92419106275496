import Vue from 'vue'

import BaseVue from '../BaseVue'

export default class Image extends BaseVue {
    render() {
        const props = this.props
        const { tips = [] } = props
        return new Vue({
            template: `
            <el-upload class="avatar-uploader" action="${props.action || ''}" :show-file-list="false" :on-success="handleSuccess"
                :before-upload="beforeUpload">
                <img v-if="thumbnail" :src="thumbnail" class="avatar">
                <div v-else>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                    <div slot="tip" class="el-upload__tip" v-if="tips.length == 0">请上传不大于2M，格式为JPEG/PNG的图片</div>
                    <div slot="tip" class="el-upload__tip" v-else>
                        <div v-for="tip of tips" v-text="tip"></div>
                    </div>
                </div>
            </el-upload>
            `,
            data: {
                thumbnail: '',
                tips: tips,
            },
            methods: {
                handleSuccess(res, file) {
                    this.thumbnail = res.file_path
                    if (props.onUploadSuccess && props.onUploadSuccess instanceof Function) {
                        props.onUploadSuccess(res)
                    }
                },
                beforeUpload(file) {
                    return new Promise((resolve, reject) => {
                        const isAllowType = (props.allowFileType || ['image/jpeg', 'image/png']).indexOf(file.type) != -1
                        const isLtAllowSize = file.size / (1024 * 1024) < (props.allowFileSize || 2)

                        if (!isAllowType) {
                            layer.msg('上传图片格式不正确！')
                            reject()
                            return isAllowType
                        }
                        if (!isLtAllowSize) {
                            layer.msg('上传图片大小不正确！')
                            reject()
                            return isLtAllowSize
                        }

                        resolve()
                        return isAllowType && isLtAllowSize
                    })
                },
            },
            mounted() {
                if (props.defaultValue) {
                    this.thumbnail = props.defaultValue
                }
            }
        }).$mount(props.el)
    }
}