import Vue from 'vue'

import BaseVue from '../BaseVue'
import { arrayToTree, request } from '../../utils'

/**
 * 用法
 * 
 * 
 *			new ZDCascader.Cascader({
 *				el: '#catest',
 *              defaultValue: '1,2,3'
 *				dataSource: '?c=Curriculum&a=get_types',
 *				dataTreeFormat: {
 *					id: 'id',
 *					pid: 't_id'
 *				},
 *				onChange: function (res) {
 *					console.log(res)
 *				}
 *			})
 */

export default class Cascader extends BaseVue {
    constructor(props) {
        super(props)

        this.setSelected = this.setSelected.bind(this)
    }

    setSelected(value) {
        let selected = value instanceof Array ? value : value.split(',')
        selected = selected.filter(item => item != 0 && item != '' && typeof item != 'undefined')
        this.vm.selected = selected
    }

    render() {
        const self = this
        const props = this.props
        return new Vue({
            template: `
            <el-cascader class="type_casecader ${props.className || ''}" placeholder="${props.placeholder || '请选择分类'}" :options="TypeTree" :props="props"
            size="${props.size || 'small'}" :clearable="true" filterable change-on-select @change="handleChange" v-model="selected"></el-cascader>
            `,
            data: {
                TypeTree: [],
                props: {
                    value: 'id',
                    label: 'name',
                },
                selected: [],
            },
            methods: {
                handleChange(res) {
                    if (props.onChange && props.onChange instanceof Function) {
                        props.onChange(res)
                    }
                }
            },
            mounted() {
                if (props.dataSource && props.dataTreeFormat && props.dataTreeFormat.id && props.dataTreeFormat.pid) {
                    request({
                        url: props.dataSource,
                    }).then((res) => {
                        if (res.success && res.data) {
                            this.TypeTree = arrayToTree(res.data, props.dataTreeFormat.id, props.dataTreeFormat.pid)
                        }
                    }).catch((err) => {
                        console.error('级联组件初始化数据失败！');
                    })
                }

                if(props.defaultValue) {
                    let selected = props.defaultValue instanceof Array ? props.defaultValue : props.defaultValue.split(',')
                    selected = selected.filter(item => item != 0 && item != '' && typeof item != 'undefined')
                    this.selected = selected
                }
            }
        }).$mount(props.el)
    }
}