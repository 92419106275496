import Vue from 'vue'

import BaseVue from '../BaseVue'
import { config } from '../../utils'

/**
 * 用法
 * 目前只封装了腾讯云的视频播放组件
 * 
 *			new ZDMedia.Video({
 *				el: '#recommend_list',
 *				dataSource: '?c=curriculum&a=recommend',
 *				contentUrl: '?c=curriculum&a=content'
 *			})
 */
let count = 0;

export default class Video extends BaseVue {
    render() {
        count++;
        const { 
            el, 
            className, 
            poster, 
            fileID, 
            width = 600, 
            height = 300, 
            controlBar = { QualitySwitcherMenuButton: false }, 
            autoContinuePlay = false,
            onTimeUpdate = function (currentTime) {}
        } = this.props
        return new Vue({
            template: `
            <video id="player-container-id-${count}" class="${className}" width="${width}" height="${height}" poster="${poster}" playsinline webkit-playinline x5-playinline></video>
            `,
            data: {},
            methods: {
            },
            mounted() {
                var player = TCPlayer(`player-container-id-${count}`, { // player-container-id 为播放器容器ID，必须与html中一致
                    fileID: fileID, // 请传入需要播放的视频filID 必须
                    appID: config.tcVideoAppID, // 请传入点播账号的appID 必须
                    controlBar: controlBar,
                    plugins: {
                        ContinuePlay: {
                            auto: autoContinuePlay, // 是否在播放时自动续播 
                            text: '上次看到 ', // 提示文案
                            btnText: '恢复播放' // 按钮文案
                        }
                    }
                });

                player.on('timeupdate', () => onTimeUpdate.call(player, player.currentTime()))
            }
        }).$mount(el)
    }
}