import Vue from 'vue'
import _ from 'lodash'

import BaseVue from '../BaseVue'

/**
 * 用法
 * 
 * 
 *			new ZDInput.SelectablePrefixInput({
 *				el: '#catest',
 *			})
 */

export default class SelectablePrefixInput extends BaseVue {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            el,
            onChange,
            onBlur,
            defaultValue = '',
            prefix = [
                'http://',
                'https://'
            ],
            size = 'small',
        } = this.props
        return new Vue({
            template: `
            <el-input placeholder="请输入内容" v-model="value" class="input-with-select" size='${size}' @change="handleChange" @blur="handleBlur">
                <el-select style="width:100px;" v-model="select" slot="prepend" placeholder="请选择" @change="handlePrefixChange">
                    <el-option v-for="prefix of prefixs" :label="prefix" :value="prefix"></el-option>
                </el-select>
            </el-input>
            `,
            data: {
                prefixs: prefix,
                select: '',
                value: '',
            },
            methods: {
                handlePrefixChange() {
                    onChange instanceof Function && onChange(this.select + this.value)
                },
                handleChange() {
                    onChange instanceof Function && onChange(this.select + this.value)
                },
                handleBlur() {
                    onBlur instanceof Function && onBlur(this.select + this.value)
                }
            },
            mounted: function () {
                prefix.forEach(element => {
                    let strStart = defaultValue.indexOf(element)
                    if (strStart === -1) {
                        return;
                    }

                    this.value = defaultValue.substr(element.length)
                    this.select = element
                });
            }
        }).$mount(el)
    }
}