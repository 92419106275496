import Vue from 'vue'

import BaseVue from '../BaseVue'

/**
 * 用法
 * 
 * 
 *			new ZDInput.YesNoSwitch({
 *				el: '#catest',
 *              default: true || false,
 *              reversal: true || false,
 *              active-text: '',
 *              inactive-text: ''
 *			})
 */

export default class YesNoSwitch extends BaseVue {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            el,
            onChange,
            defaultValue = false,
            activeText = '',
            inactiveText = '',
        } = this.props

        return new Vue({
            template: `
            <el-switch
                v-model="value"
                active-text="${activeText}"
                inactive-text="${inactiveText}"
                @change="handleChange"
            >
            </el-switch>
            `,
            data: {
                value: defaultValue
            },
            methods: {
                handleChange(res) {
                    onChange(res)
                }
            }
        }).$mount(el)
    }
}