import Vue from 'vue'
import _ from 'lodash'

import BaseVue from '../BaseVue'

/**
 * 用法
 * 
 * 
 *			new ZDInput.SuffixInput({
 *				el: '#catest',
 *			})
 */

export default class SuffixInput extends BaseVue {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            el,
            onChange,
            defaultValue = '',
            suffix = 'suffix',
            size = 'small',
        } = this.props
        return new Vue({
            template: `
            <el-input placeholder="请输入前缀" v-model="value" size="${size}" @change="handleChange">
                <template slot="append">${suffix}</template>
            </el-input>
            `,
            data: {
                loading: false,
                value: defaultValue,
            },
            methods: {
                handleChange(value) {
                    onChange(value)
                },
            },
        }).$mount(el)
    }
}