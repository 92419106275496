import Vue from 'vue'
import _ from 'lodash'

import BaseVue from '../BaseVue'
import { request } from '../../utils'

/**
 * 用法
 * 
 * 
 *			new ZDInput.AutoComplete({
 *				el: '#catest',
 *              defaultValue: '1,2,3'
 *				dataSource: '?c=Curriculum&a=get_types',
 *				onSelect: function (res) {
 *					console.log(res)
 *				}
 *			})
 */

export default class AutoComplete extends BaseVue {
    render() {
        const props = this.props
        return new Vue({
            template: `
            <el-autocomplete class="${props.className || ''}" style="${props.style || ''}" v-model="currentItem" :fetch-suggestions="querySearch" placeholder="${props.placeholder || ''}"
            :trigger-on-focus="false" @select="handleSelect" @change="handleChange"></el-autocomplete>
            `,
            data: {
                currentItem: props.defaultValue || '',
                items: []
            },
            methods: {
                querySearch(queryString, cb) {
                    var items = this.items;
                    var results = queryString ? items.filter(this.createFilter(queryString)) : items;
                    var ret = _.cloneDeep(results).map(function (result) {
                        return {
                            id: result.id,
                            value: result.name
                        }
                    })
                    // 调用 callback 返回建议列表的数据
                    cb(ret);
                },
                createFilter(queryString) {
                    return (items) => {
                        return (items.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1);
                    };
                },
                handleSelect(item) {
                    if (props.onSelect && props.onSelect instanceof Function) {
                        props.onSelect(_.cloneDeep(item))
                    }
                },
                handleChange(value) {
                    if (props.onChange && props.onChange instanceof Function) {
                        props.onChange(value)
                    }
                },
                fetchCompanyList() {
                    var _self = this
                    request({
                        url: props.dataSource,
                    }).then(function (res) {
                        _self.items = res.data
                    }).catch(function (err) {
                        console.error(err)
                    })
                }
            },
            mounted() {
                this.fetchCompanyList();
            }
        }).$mount(props.el)
    }
}