import { request } from '../../utils'

export default class Curd {
    constructor() {
        this.formsubmit = false;
    }

    create(url, options) {
        var self = this;
        
        if (!this.submiting()) {
            return false;
        }

        return request({
            url,
            method: "POST",
            data: options && options.data ? options.data : {}
        }).then(function (res) {
            self.formsubmit = false;
            return res;
        });
    }

    update(url, options) {
        var self = this;
        if (!this.submiting()) {
            return false;
        }

        return request({
            url,
            method: "POST",
            data: options && options.data ? options.data : {}
        }).then(function (res) {
            self.formsubmit = false;
            return res;
        });
    }

    read(url, options) {
        var self = this;
        return request({
            url,
            method: "POST",
            data: options && options.data ? options.data : {}
        }).then(function (res) {
            self.formsubmit = false;
            return res;
        });
    }

    del(url, options) {
        var self = this;
        return new Promise(function (resolve, reject) {
            layer.confirm('您确定要删除选中数据吗？', {
                btn: ['确定', '取消'] //按钮
            }, function (index) {
                if (!self.submiting()) {
                    return false;
                }

                layer.close(index)

                request({
                    url,
                    method: "POST",
                    data: options && options.data ? options.data : {},
                    beforeSend: function () {
                        //beforeSend
                    }
                }).then(function (res) {
                    resolve(res);
                }).catch(function (err) {
                    reject(err)
                }).finally(() => {
                    self.formsubmit = false;
                })
            }, function (index) {
                layer.close(index);
            });

        })
    }

    submiting() {
        if (this.formsubmit) {
            layer.msg('正在提交数据，请勿重复提交！');
            return false;
        }

        this.formsubmit = true
        return true;
    }
}  