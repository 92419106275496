import _ from 'lodash'
import qs from 'qs'

import Curd from './Curd'

export default class BtCurd extends Curd {
    constructor(bTable, urls) {
        super()
        this.bTable = bTable
        this.urls = _.assign({
            create: "",
            update: "",
            read: "",
            del: ""
        }, urls)
    }

    setUrl(type, url) {
        var type = type || null;
        var url = url || null;

        if (type === null || url === null) {
            console.error('Please set url`s type or location!');
            return false;
        }

        this.urls[type] = url;
        return true;
    }

    newBTable(options) {
        var tableOptions = $.extend({
            method: 'POST',
            contentType: "application/x-www-form-urlencoded",
            url: this.urls.read,
            uniqueId: "id",
            striped: true,      //是否显示行间隔色
            cache: false,      //是否使用缓存，默认为true，所以一般情况下需要设置一下这个属性（*）
            pagination: true,     //是否显示分页（*）
            sortable: false,      //是否启用排序
            paginationLoop: false,//设置为 true 启用分页条无限循环的功能。
            sortOrder: "desc",     //排序方式
            pageNumber: 1,      //初始化加载第一页，默认第一页
            pageSize: 20,      //每页的记录行数（*）
            pageList: [10, 25, 50, 100],  //可供选择的每页的行数（*）
            queryParamsType: '', //默认值为 'limit' ,在默认情况下 传给服务端的参数为：offset,limit,sort
            // 设置为 ''  在这种情况下传给服务器的参数为：pageSize,pageNumber
            //queryParams: queryParams,//前端调用服务时，会默认传递上边提到的参数，如果需要添加自定义参数，可以自定义一个函数返回请求参数
            sidePagination: "server",   //分页方式：client客户端分页，server服务端分页（*）
            strictSearch: true,
            minimumCountColumns: 2,    //最少允许的列数
            clickToSelect: true,    //是否启用点击选中行
            searchOnEnterKey: true,
            paginationPreText: "上一页",
            paginationNextText: "下一页",
            rowStyle: function (row, index) {
                var style = "";
                style = 'lh';
                return { classes: style }
            }
            /*,
            onClickCell: function (field, value,row,td) {
                    if(field=='name'){
                            var url = '?c={$controller}&a=edit&id='+row.id;
                            layer_show('修改区域',url,550,500);
                    }
            }*/
        }, options);

        this.bTable.bootstrapTable('destroy').bootstrapTable(tableOptions);
    }

    add(params) {
        var self = this;

        return new Promise((resolve, reject) => {
            this.create(self.urls.create, {
                data: params
            }).then((res) => {
                layer.msg(res.msg);
                resolve(res)
            }).catch((err) => {
                this.formsubmit = false
                console.log(err)
                reject(err)
            });
        })
    }

    patch(params) {
        return new Promise((resolve, reject) => {
            var ids = this.getBTSelectedID();

            if (ids) {
                var data = {
                    ids: ids.join(','),
                    ...params,
                };

                this.update(this.urls.update, {
                    data: data,
                }).then((res) => {
                    layer.msg(res.msg);
                    this.reload().then(resolve);
                }).catch((err) => {
                    console.log(err)
                    reject(err)
                }).finally(() => {
                    this.formsubmit = false
                });
            } else {
                layer.msg('未选中!');
            }
        })
    }

    review(state) {
        var ids = this.getBTSelectedID();

        if (ids) {
            var data = {
                ids: ids.join(','),
                state
            };

            this.update(this.urls.review, {
                data: data,
            }).then((res) => {
                layer.msg(res.msg);
                return this.reload();
            }).catch(function (err) {
                console.log(err)
            });
        } else {
            layer.msg('未选中!');
        }
    }

    remove() {
        var self = this;
        var ids = this.getBTSelectedID();

        return new Promise(function (resolve) {
            if (ids) {
                var data = {
                    ids: ids.join(',')
                };

                self.del(self.urls.del, {
                    data: data,
                }).then(function (res) {
                    layer.msg(res.msg || '删除成功！');
                    return self.reload()
                }).then(() => {
                    resolve()
                }).catch(function (err) {
                    layer.msg(err.data.errorMsg);
                }).finally(() => {
                    self.formsubmit = false
                });
            } else {
                layer.msg('未选中!');
            }
        })
    }

    removeOne(id) {
        var self = this;
        var ids = parseInt(id);
        return new Promise(function (resolve) {
            if (ids) {
                var data = {
                    ids: ids
                };

                self.del(self.urls.del, {
                    data: data
                }).then(function (res) {
                    self.reload()
                    layer.msg(res.msg);
                    resolve(res)
                }).catch(function (err) {
                    layer.msg(err.message);
                });
            } else {
                layer.msg('未传入参数!');
            }

        })
    }

    reload(url, data = {}) {
        var self = this;

        return self.bTable.bootstrapTable('refresh', { silent: true });

        // if (url) {
        //     const queryParams = '&' + qs.stringify(_.cloneDeep(data), { encode: false });
        //     self.urls.read = url + queryParams
        // }

        // return this.read(self.urls.read)
        //     .then(function (res) {
        //         self.bTable.bootstrapTable('load', res.data)
        //         return res;
        //     }).catch(function (err) {
        //         layer.msg(err.data)
        //     });
    }

    getBTSelectedID() {
        var self = this
        var ids = [];
        $.map(self.bTable.bootstrapTable('getSelections'), function (row) {
            ids.push(row.id)
        });

        if (ids.length === 0) {
            return false;
        }

        return ids;
    }
}