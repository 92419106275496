import Vue from 'vue'

import BaseVue from '../BaseVue'
import { mp4Parser, request } from '../../utils'

export default class Video extends BaseVue {
    render() {
        const props = this.props
        const { defaultValue, disabled = false } = props
        const fileList = defaultValue.name ? [defaultValue] : []

        return new Vue({
            template: `
            <div style="display: flex;flex-direction: row;">
                <input v-if="fileID != 0" type="button" id="btn_video_box" class="btn btn-warning btn-sm" value="查看" style="margin-bottom: 0;margin-right: 5px;" @click="openVideo"/>
                <el-upload action="" ref="elUpload" :before-upload="beforeUpload" :show-file-list="false" :http-request="handleRequest" :disabled="disabled">
                    <div style="display: inline-flex;flex-direction:row;align-items:center">
                        <input v-if="fileID != 0" type="button" id="btn_video_box" class="btn btn-danger btn-sm" value="重新上传" @click.stop.prevent="handleReUpload"  style="margin-bottom: 0;margin-right: 5px;" :disabled="disabled"/>
                        <input v-else type="button" id="btn_video_box" class="btn btn-warning btn-sm" value="上传"  style="margin-bottom: 0;margin-right: 5px;" :disabled="disabled"/>
                        <span style="color:#999999;margin-right: 5px;">格式：mp4 容量：60GB以内</span>
                        <el-progress v-if="progressShow" type="circle" :width="26" :stroke-width="2" :percentage="progressInfo.current" :status="progressInfo.status"></el-progress>
                        <span style="margin-left: 5px;color:#999999;" v-text="progressInfo.value"></span>
                    </div>
                </el-upload>
            </div>
            `,
            data: {
                fileID: defaultValue && defaultValue.name ? defaultValue.name : 0,
                fileUrl: defaultValue && defaultValue.url ? defaultValue.url : '',
                fileList: fileList,
                progressShow: false,
                progressInfo: {
                    value: '',
                    current: 0,
                    status: ''
                },
                uploadCos: '',
                uploadTaskId: '',
                disabled: !!disabled,
            },
            methods: {
                openVideo() {
                    window.open(this.fileUrl)
                },
                getSignature(callback, qcContext) {
                    
                    request({
                        url: props.signatureUrl
                    }).then((res) => {
                        if (res.success && res.data && res.data.signature) {
                            callback.call(qcContext, res.data.signature)
                        } else {
                            this.progressInfo.value = '获取签名失败'
                            this.progressInfo.status = 'exception'
                        }
                    }).catch((err) => {
                        this.progressInfo.value = '获取签名失败'
                        this.progressInfo.status = 'exception'
                    })
                },
                handleReUpload() {
                    if (this.fileList.length > 0) {
                            
                        this.progressInfo = {
                            value: '清空文件，重新上传',
                            current: 0,
                            status: ''
                        }

                        request({
                            url: props.delFileUrl || '/api.php?c=video&a=del',
                            method: 'POST',
                            data: {
                                file_id: this.fileID,
                            }
                        }).then((res) => {
                            this.fileID = 0
                            this.fileUrl = ''
                            this.fileList = []
                        }).catch((err) => {
                            // qcVideo.ugcUploader.cancel({
                            //     cos: this.uploadCos,   
                            //     taskId: this.uploadTaskId
                            // });
                            this.progressInfo.value = '文件删除失败！'
                            this.progressInfo.status = 'exception'
                            layer.msg('文件删除失败！')
                        })
                    }
                },
                handleRequest() {
                    var _self = this
                    if (!qcVideo || !qcVideo.ugcUploader) {
                        return;
                    }

                    this.progressShow = true
                    this.progressInfo.value = '上传中'

                    qcVideo.ugcUploader.start({
                        videoFile: this.fileList[0],//视频，类型为 File
                        // coverFile: this.coverFile,//封面, 类型为 File
                        getSignature: function (callback) {
                            return _self.getSignature.call(_self, callback, this);
                        },//前文中所述的获取上传签名的函数
                        error: function (result) {//上传失败时的回调函数
                            //...
                            _self.progressInfo.value = '上传失败：' + result.msg;
                            _self.progressInfo.status = 'exception'
                        },
                        progress: function (result) {
                            _self.progressInfo.current = parseInt(result.curr * 100)
                            _self.uploadCos = result.cos;
                            _self.uploadTaskId = result.taskId;
                        },
                        finish: function (result) {//上传成功时的回调函数
                            // console.log('上传结果的fileId：' + result.fileId);
                            // console.log('上传结果的视频名称：' + result.videoName);
                            // console.log('上传结果的视频地址：' + result.videoUrl);
                            _self.progressInfo = {
                                value: '上传成功',
                                current: 100,
                                status: 'success'
                            }
                            _self.fileID = result.fileId
                            _self.fileUrl = result.videoUrl
                            request({
                                url: '/api.php?c=video&a=add',
                                method: 'post',
                                data: {
                                    fileID: result.fileId,
                                    url: result.videoUrl,
                                    name: result.videoName,
                                    source: '1'
                                }
                            }).then(function (res) {
                                if (props.onUploaderSuccess && props.onUploaderSuccess instanceof Function) {
                                    props.onUploaderSuccess(result)
                                }
                            })
                        }
                    });
                },
                beforeUpload(file) {
                    return new Promise((resolve, reject) => {
                        if (file.type !== 'video/mp4') {
                            layer.msg('上传格式必须是MP4！')
                            reject('上传格式必须是MP4！')
                            return
                        }

                        this.progressShow = true
                        this.progressInfo.value = '视频文件解析中'
                        this.progressInfo.status = ''

                        mp4Parser(file).then((fileInfo) => {

                            if (!fileInfo || !fileInfo.videoCodec || fileInfo.videoCodec.indexOf('avc1') == -1) {
                                this.progressInfo = {
                                    value: '视频编码格式不正确，请先按H264编码转码！',
                                    status: 'exception'
                                }
                                reject('视频编码格式不正确，请先按H264编码转码！')
                            }

                            // if (!fileInfo || !fileInfo.audioCodec || fileInfo.audioCodec.indexOf('mp4a') == -1) {
                            //     reject('视频编码格式不正确，请先按H264编码转码！')
                            // }
                            this.fileList = [file]
                            resolve(file)

                        }).catch((err) => {
                            this.progressInfo = {
                                value: '视频解析失败！',
                                status: 'exception'
                            }
                            reject('视频解析失败！')
                        })
                    })
                }
            }
        }).$mount(props.el)
    }
}