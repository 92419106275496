import { MP4Box } from 'mp4box'

import config from './config'

let mp4box
let fileSize
let offset = 0
const { fileReaderChunkSize } = config

function initialize() {
    mp4box = new MP4Box(false)
    mp4box.onError = function (e) {
        console.log("mp4box failed to parse data.");
    }

    return mp4box
}

function readBlock(_offset, length, _file, resolve, reject) {
    const r = new FileReader()
    const blob = _file.slice(_offset, length + _offset)
    r.onload = (evt) => {
        onBlockRead(evt, _file, resolve, reject)
    }
    r.readAsArrayBuffer(blob)
}

function onBlockRead(evt, file, resolve, reject) {
    if (evt.target.error == null) {
        onparsedbuffer(evt.target.result) // callback for handling read chunk
        offset += evt.target.result.byteLength
    } else {
        console.log("Read error: " + evt.target.error)
        reject(evt.target.error)
        return
    }
    if (offset >= fileSize) {
        mp4box.flush()
        
        const originalInfo = mp4box.getInfo();
        const extractedInfo = extractInfo(originalInfo)
        
        resolve(extractedInfo)
        return
    }

    readBlock(offset, fileReaderChunkSize, file, resolve, reject)
}

function onparsedbuffer(buffer) {
    // console.log("Appending buffer with offset " + offset)
    buffer.fileStart = offset
    mp4box.appendBuffer(buffer)
}

function extractInfo(originalInfo) {
    const info = {
        created: originalInfo.created,
        duration: (originalInfo.duration / 1000).toFixed(2),
        size: 0
    }

    if (originalInfo.videoTracks && originalInfo.videoTracks.length > 0) {
        const { codec, track_width, track_height, size } = originalInfo.videoTracks[0]
        info.videoCodec = codec
        info.videoPPI = `${track_width} X ${track_height}`
        info.size += size
    }

    if (originalInfo.audioTracks && originalInfo.audioTracks.length > 0) {
        const { codec, size } = originalInfo.audioTracks[0]
        info.audioCodec = codec
        info.size += size
    }

    return info
}

export default function start(file) {
    fileSize = file.size
    offset = 0

    initialize()

    return new Promise((resolve, reject) => {
        readBlock(offset, fileReaderChunkSize, file, resolve, reject)
    })
}
