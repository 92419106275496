export default class BaseVue {
    constructor(props) {
        if (!props || !props.el) {
            console.error('缺少参数，组件初始化失败！')
            return;
        }
        
        var root = document.querySelector(props.el)
        if(!root) {
            console.error('找不到对应根节点！')
            return;
        }

        this.vm
        this.props = props

        this._init()

        this._run()

        this._mounted()
    }

    _init() {

    }

    _run() {
        this.vm = this.render()
    }

    render() {

    }

    _mounted() {
        if (this.props.didMounted && this.props.didMounted instanceof Function) {
            this.props.didMounted.call(this)
        }
    }
}